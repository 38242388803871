export declare interface QueryEntity {
  dateField?: string;
  dateQueryType?: string;

  dateQueryYear?: Date;
  dateQueryRange?: Array<Date>;

  textField?: string;
  keyword?: string;

  feeYearField?: string;
  feeYear?: number;

  userId?: number;

  legalStatus?: string;
  feeStatus?: string;

  applicants?: string;
  assignees?: string;
}

export declare interface FieldEntity {
  name?: string;
  label?: string;
}

export declare interface FieldQueryType {
  name?: string;
  label?: string;
}

export declare interface FliterOptions {
  legalStatusList?: Array<FieldQueryType>;
  feeStatusList?: Array<FieldQueryType>;
  applicantList?: Array<FieldQueryType>;
}

export const DEFAULT_DATE_FIELDS: Array<FieldEntity> = [
  { name: "appDate", label: "申请日" },
  { name: "pubDate", label: "公开日" },
  { name: "authDate", label: "授权日" },
  { name: "limitDate1", label: "待办期限" },
  { name: "feeDate", label: "缴费截止日" }
];

export const DEFULT_DATE_QUERY_TYPES: Array<FieldQueryType> = [
  { name: "range", label: "按起止日期检索" },
  { name: "year", label: "按指定年份检索" }
];

export const DEFULT_TEXT_FIELDS: Array<FieldEntity> = [
  { name: "appNo", label: "申请号" },
  { name: "pubNo", label: "公开号" },
  { name: "authNo", label: "授权号" },
  { name: "certificateNo", label: "证书号" },
  { name: "patentNo", label: "内部案号" },
  { name: "title", label: "专利名称" },
  { name: "remark", label: "备注" },
  // { name: "applicants", label: "申请人" },
  // { name: "assignees", label: "专利权人" },
  // { name: "legalStatus", label: "法律状态" }
];

export const DEFULT_DATE_PICKER_OPTIONS: any = {
  shortcuts: [
    {
      text: "未来一周",
      onClick(picker: any) {
        const start = new Date();
        const end = new Date();
        end.setDate(end.getDate() + 7);
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "未来一个月",
      onClick(picker: any) {
        const start = new Date();
        const end = new Date();
        end.setMonth(end.getMonth() + 1);
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "未来三个月",
      onClick(picker: any) {
        const start = new Date();
        const end = new Date();
        end.setMonth(end.getMonth() + 3);
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "之前一周",
      onClick(picker: any) {
        const start = new Date();
        start.setDate(start.getDate() - 7);
        const end = new Date();
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "之前一个月",
      onClick(picker: any) {
        const start = new Date();
        start.setMonth(start.getMonth() - 1);
        const end = new Date();
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "之前三个月",
      onClick(picker: any) {
        const start = new Date();
        start.setMonth(start.getMonth() - 3);
        const end = new Date();
        picker.$emit("pick", [start, end]);
      }
    },
    {
      text: "今年全年",
      onClick(picker: any) {
        const start = new Date();
        start.setMonth(0);
        start.setDate(1);

        const end = new Date();
        end.setMonth(11);
        end.setDate(31);

        picker.$emit("pick", [start, end]);
      }
    }
  ]
};
