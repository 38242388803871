























































































































































































































































































































































































































































































































































































































































































import {
  API_EXPORT_TASK_SYNC,
  API_PATENT_ADD_BY_OPEN_API,
  API_PATENT_FILTER_OPTIONS,
  API_PATENT_LIST,
  API_PATENT_LIST_BY_OPEN_API,
  API_USER_LIST,
} from "@/configs/Apis";
import {
  MSG_001,
  MSG_EXPORT_FILE_NOTFOUND,
  MSG_INVALID_PAGE,
} from "@/configs/Consts";
import {
  DEFAULT_DATE_FIELDS,
  DEFULT_DATE_PICKER_OPTIONS,
  DEFULT_DATE_QUERY_TYPES,
  DEFULT_TEXT_FIELDS,
  FieldEntity,
  FieldQueryType,
  FliterOptions,
  QueryEntity,
} from "@/entities/PatentQuery";
import commonStore from "@/stores/modules/views/common";
import formatters from "@/utils/ColumnFormater";
import {
  hasPermission,
  isEmpty,
  isEmptyOrWhiteSpace,
  mergeColumns,
  showConfirm,
  showError,
  showSuccess,
  showWarning,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import PatentDetail from "@/views/patent/subviews/PatentDetail.vue";
import PatentForm from "@/views/patent/subviews/PatentForm.vue";
import PatentBatchCreateForm from "@/views/patent/subviews/PatentBatchCreateForm.vue";
import DataImporter from "@/views/importer/DataImporter.vue";
import PatentUserSelector from "@/views/patent/subviews/PatentUserSelector.vue";
import { TableColumn } from "element-ui/types/table-column";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    PatentDetail,
    PatentForm,
    PatentBatchCreateForm,
    DataImporter,
    PatentUserSelector,
  },
})
export default class PatentIndex extends Vue {
  nodeQueryData = "";

  addPatentFormDialogVisible = false;
  addPatentBatchCreateFormDialogVisible = false;
  patentItem: any = { id: 0, appType: "FM", text: {}, applicantList: null, inventorList: null, patentPriorityList: null };

  patentImportVisible = false;

  batchPatentFilter: any = {};

  selectUserDialogVisible = false;

  showRight = false;
  hasSelectedItems = false;
  showInputDialog = false;
  addPatentGridEmptyText = "暂无数据（请稍后重试）";
  queryData: QueryEntity = { dateQueryType: "range" };
  patentDetail: any = {};
  queryStr = "";
  loadData = false;
  dateFields: Array<FieldEntity> = DEFAULT_DATE_FIELDS;
  dateQueryTypes: Array<FieldQueryType> = DEFULT_DATE_QUERY_TYPES;

  showNextDetailItem = true;
  showPrevDetailItem = true;
  dataPosition = "";

  filterOptions: FliterOptions = {
    legalStatusList: [],
    feeStatusList: [],
    applicantList: [],
  };

  showSearch = false;

  notRecycle = true;

  loadingUser = false;
  userList: Array<any> = [];

  bindingType = "binding";
  bindingTitle = "将客户与专利进行关联";

  showRemarkDialog = false;
  remarkData: any = {};

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  queryUser(keyword: string) {
    if (isEmptyOrWhiteSpace(keyword)) {
      this.userList = [];
      return;
    }
    this.loadingUser = true;
    Ajax.queryData(
      API_USER_LIST,
      { keyword: keyword, userType: "CUSTOMER", pageSize: 20 },
      "POST"
    )
      .then((rs: any) => {
        this.loadingUser = false;
        this.userList = rs.data || [];
      })
      .catch(() => {
        this.loadingUser = false;
        this.userList = [];
      });
  }

  loadFilterOptions() {
    Ajax.queryData(API_PATENT_FILTER_OPTIONS, {}, "GET")
      .then((rs: any) => {
        this.filterOptions = rs || {
          legalStatusList: [],
          feeStatusList: [],
          applicantList: [],
        };
      })
      .catch(() => {
        this.filterOptions = {
          legalStatusList: [],
          feeStatusList: [],
          applicantList: [],
        };
      });
  }

  loadMoreData(item: any) {
    const showData = item.showData;
    if (!showData) {
      return;
    }
    const index = showData.index + 1;
    const start = index * showData.size;
    let end = start + showData.size;
    if (end > item.children.length) {
      end = item.children.length;
    }
    showData.index = index;
    showData.data = [].concat(showData.data, item.children.slice(start, end));
  }

  get treeData() {
    const module = this.$store.state.patent;
    if (!module) {
      return null;
    }
    return module.patentTree;
  }

  onTreeNodeClick(index: string) {
    if (isEmptyOrWhiteSpace(index)) {
      return;
    }
    const parts = index.split("-");
    let nodeData: any = null;
    if (parts.length === 1) {
      nodeData = this.treeData[parts[0]];
    } else {
      nodeData = this.treeData[parts[0]].children[parts[1]];
    }
    if (!nodeData) {
      return;
    }
    this.notRecycle = (nodeData.patentFilter || {}).deletedFlag !== 2;
    this.nodeQueryData = JSON.stringify(nodeData.patentFilter || {});
    // this.onQueryChanged();
  }

  get textFields(): Array<FieldEntity> {
    return JSON.parse(JSON.stringify(DEFULT_TEXT_FIELDS));
  }

  get searchBtnIcon(): string {
    return this.showSearch ? "el-icon-arrow-up" : "el-icon-arrow-down";
  }

  datePickerOptions: any = DEFULT_DATE_PICKER_OPTIONS;

  // --- ADD PATENT ATTRIBUTES BEGIN--- //
  addPatentQueryStr = "";
  addPatentShowGrid = false;
  addPatentSearchData = {
    patentNo: "",
    agencyName: "",
    patentUser: "",
    inventor: "",
  };

  addPatentShowInputDialog = false;
  addPatentInputMode = "";

  // --- ADD PATENT ATTRIBUTES END--- //
  get dataApi() {
    return API_PATENT_LIST;
  }

  get colFormatters() {
    return formatters;
  }

  createTableColumns() {
    const defaultColumns: Array<TableColumnOption> = [
      { prop: "patentNo", display: "内部案号", width: 140, clickable: true },
      { prop: "appNo", display: "申请号", width: 200, clickable: true },
      { prop: "appDate", display: "申请日", width: 100 },
      { prop: "pubDate", display: "公开日", width: 140 },
      { prop: "pubNo", display: "公开号", width: 140 },
      { prop: "authDate", display: "授权日", width: 140 },
      { prop: "authNo", display: "授权号", width: 140 },
      { prop: "title", display: "专利名称", width: 200, clickable: true },
      { prop: "doublePatentNo", display: "同日案号", width: 140, clickable: true },
      { prop: "certificateNo", display: "证书号", width: 140 },
      // { prop: "patentFileList", display: "事项", width: 240, sortable: false },
      { prop: "legalStatus", display: "法律状态", width: 110 },
      { prop: "feeAmount", display: "待缴官费", width: 110 },
      { prop: "feeAmountDetail", display: "待缴明细", width: 150 },
      { prop: "feeDate", display: "缴费截止日", width: 130 },
      { prop: "feeStatus", display: "年费状态", width: 120 },
      { prop: "nextFeeYear", display: "待缴年费年度", width: 150 },
      { prop: "paidFeeYear", display: "已缴年费年度", width: 150 },
      { prop: "feeError", display: "费用异常", width: 120 },
      { prop: "inteAppDate", display: "国际申请日", width: 140 },
      { prop: "inteAppNo", display: "国际申请号", width: 140 },
      { prop: "intePubDate", display: "国际公布日", width: 140 },
      { prop: "intePubNo", display: "国际公布号", width: 140 },
      { prop: "applicants", display: "申请人", width: 200 },
      { prop: "assignees", display: "专利权人", width: 200 },
      { prop: "applicantAddress", display: "地址", width: 200 },
      { prop: "inventors", display: "发明(设计)人", width: 200 },
      { prop: "agencyName", display: "代理机构", width: 200 },
      // { prop: "agentName", display: "代理人", width: 120 },
      {
        prop: "remark",
        display: "备注",
        width: 200,
        sortable: false,
        clickable: true
      },
      { prop: "createdTime", display: "创建时间", width: 160 },
      { prop: "modifiedTime", display: "更新时间", width: 160 },
      { prop: "lastSyncTime", display: "最近同步时间", width: 160 },
    ];
    this.$store
      .dispatch("patent/getListLayout", { listName: "PATENT" })
      .then((layout) => {
        const columns = mergeColumns(
          JSON.parse(layout || "[]"),
          defaultColumns
        );
        this.applyColumnFormatters(columns);
        return columns;
      })
      .then((columns) => this.$store.dispatch("patent/setColumns", columns));
  }

  applyColumnFormatters(columns: Array<TableColumnOption>) {
    const fmers = {
      appNo: this.colFormatters.formatPatentNo.bind(this),
      feeAmount: (data: any, column: any, value: any) =>
        `<div class="cell-amount">${this.colFormatters.formatAmount(
          data,
          column,
          value
        )}</div>`,
      feeDate: this.colFormatters.formatFeeOrderDateForList.bind(this),
      legalStatus: this.colFormatters.formatPatentStatus.bind(this),
      patentFileList: this.colFormatters.formatPatentFiles.bind(this),
      feeStatus: this.colFormatters.formatFeeStatus.bind(this),
      lastSyncTime: this.colFormatters.formatLastSyncTime.bind(this),
      remark: (data: any, column: any, value: any) => isEmpty(value) ? "--" : value
    };
    if (!columns || !columns.length) {
      return;
    }
    columns.forEach((option) => {
      if (!Object.prototype.hasOwnProperty.call(fmers, option.prop)) {
        return;
      }
      option.formatter = (fmers as any)[option.prop];
    });
  }

  importPatent() {
    this.patentImportVisible = true;
  }

  closeImportDialog() {
    this.patentImportVisible = false;
    this.reloadTreeAndData();
  }

  reloadTreeAndData() {
    this.loadPatentTree();
    this.searchData();
  }

  onModuleRegistered() {
    this.$nextTick()
      .then(() => this.createTableColumns())
      .then(() => {
        this.loadPatentTree();
        this.onSearchConditionChange();
        this.loadFilterOptions();
      });
  }

  loadPatentTree() {
    return this.$store.dispatch("patent/getPatentTree");
  }

  onTextKeyup() {
    ((this.$refs.searchBtn as Vue).$el as HTMLInputElement).focus();
  }

  onSearchConditionChange() {
    const params: { [key: string]: any } = {};
    if (!isEmptyOrWhiteSpace(this.nodeQueryData)) {
      const nodeFilter = JSON.parse(this.nodeQueryData);
      Object.keys(nodeFilter).forEach(
        (name) => (params[name] = nodeFilter[name])
      );
    }
    if (
      !isEmptyOrWhiteSpace(this.queryData.dateField) &&
      !isEmptyOrWhiteSpace(this.queryData.dateQueryType)
    ) {
      if (
        this.queryData.dateQueryType === "year" &&
        this.queryData.dateQueryYear
      ) {
        const year = this.queryData.dateQueryYear.getFullYear();
        switch (this.queryData.dateField) {
          case "feeDate":
            params.feeStartDate = year + "-01-01";
            params.feeEndDate = year + "-12-31";
            break;
          case "limitDate1":
            params.limitStartDate1 = year + "-01-01";
            params.limitEndDate1 = year + "-12-31";
            break;
          case "appDate":
            params.appStartDate = year + "-01-01";
            params.appEndDate = year + "-12-31";
            break;
          case "pubDate":
            params.pubStartDate = year + "-01-01";
            params.pubEndDate = year + "-12-31";
            break;
          case "authDate":
            params.authStartDate = year + "-01-01";
            params.authEndDate = year + "-12-31";
            break;
          default:
        }
      } else if (
        this.queryData.dateQueryType === "range" &&
        this.queryData.dateQueryRange &&
        this.queryData.dateQueryRange.length === 2
      ) {
        switch (this.queryData.dateField) {
          case "feeDate":
            params.feeStartDate = this.queryData.dateQueryRange[0];
            params.feeEndDate = this.queryData.dateQueryRange[1];
            break;
          case "limitDate1":
            params.limitStartDate1 = this.queryData.dateQueryRange[0];
            params.limitEndDate1 = this.queryData.dateQueryRange[1];
            break;
          case "appDate":
            params.appStartDate = this.queryData.dateQueryRange[0];
            params.appEndDate = this.queryData.dateQueryRange[1];
            break;
          case "pubDate":
            params.pubStartDate = this.queryData.dateQueryRange[0];
            params.pubEndDate = this.queryData.dateQueryRange[1];
            break;
          case "authDate":
            params.authStartDate = this.queryData.dateQueryRange[0];
            params.authEndDate = this.queryData.dateQueryRange[1];
            break;
          default:
        }
      }
    }
    if (
      !isEmptyOrWhiteSpace(this.queryData.textField) &&
      !isEmptyOrWhiteSpace(this.queryData.keyword)
    ) {
      if (this.queryData.textField !== "all") {
        params[this.queryData.textField as any] = this.queryData.keyword;
      }
    }
    if (this.queryData.userId && this.queryData.userId > 0) {
      params.userId = this.queryData.userId;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.legalStatus)) {
      params.legalStatus = this.queryData.legalStatus;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.feeStatus)) {
      params.feeStatus = this.queryData.feeStatus;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.applicants)) {
      params.applicants = this.queryData.applicants;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.assignees)) {
      params.assignees = this.queryData.assignees;
    }
    const paramsStr = JSON.stringify(params);
    if (paramsStr === "{}" && this.queryStr.trim() === "") {
      this.queryStr = "{}";
      return;
    }
    if (paramsStr !== this.queryStr.trim()) {
      this.queryStr = JSON.stringify(params);
    }
  }

  @Watch("nodeQueryData")
  onNodeQueryDataChange() {
    this.queryData = { dateQueryType: "range" };
    this.onSearchConditionChange();
  }

  @Watch("queryData.dateField")
  @Watch("queryData.textField")
  @Watch("queryData.feeYearField")
  @Watch("queryData.dateQueryType")
  @Watch("queryData.userId")
  @Watch("queryData.legalStatus")
  @Watch("queryData.feeStatus")
  @Watch("queryData.applicants")
  @Watch("queryData.assignees")
  onSeachPanelConditionChange() {
    this.onSearchConditionChange();
  }

  searchData() {
    if (this.queryStr.endsWith(" ")) {
      this.queryStr = this.queryStr.trim();
    } else {
      this.queryStr += " ";
    }
  }

  addPatentManual(cmd: string) {
    if (cmd === "single") {
      this.addPatent("manual");
    } else {
      this.patentItem = { id: 0, appType: "FM", text: {}, applicantList: null, inventorList: null, patentPriorityList: null, batchCreateList: null };
      this.addPatentBatchCreateFormDialogVisible = true;
    }
  }

  addPatent(cmd: string) {
    if (cmd === "manual") {
      this.patentItem = { id: 0, text: {}, applicantList: null, inventorList: null, patentPriorityList: null };
      this.addPatentFormDialogVisible = true;
      return;
    }
    this.showPatentNoDialog(cmd);
  }

  addPatentSuccess() {
    this.addPatentFormDialogVisible = false;
    this.reloadTreeAndData();
  }

  addPatentBatchSuccess() {
    this.addPatentBatchCreateFormDialogVisible = false;
    this.reloadTreeAndData();
  }

  exportData(cmd: string) {
    let dataFilter: any = {};
    if (cmd === "selected") {
      const selectedRows = (this.$refs.patentgrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      dataFilter.selectedIds = selectedRows;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        dataFilter = JSON.parse(this.queryStr);
      }
    }
    const fieldParams: Array<any> = [];
    ((this.$refs.patentgrid as any).columns || []).forEach((column: any) => {
      if (column.hidden) {
        return;
      }
      if (column.prop === "appNo") {
        fieldParams.push({
          field: "appType",
          label: "申请类型",
          width: column.width || 30,
        });
      }
      fieldParams.push({
        field: column.prop,
        label: column.display,
        width: column.width || 30,
      });
    });
    const params: any = {
      dataType: "Patent",
      fieldParams: JSON.stringify(fieldParams),
      dataFilter: JSON.stringify(dataFilter),
    };
    Ajax.queryData(API_EXPORT_TASK_SYNC, params)
      .then((task: any) => {
        const url = task.resultUrl || "";
        if (isEmpty(url)) {
          showWarning(MSG_EXPORT_FILE_NOTFOUND);
          return;
        }
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        document.body.appendChild(a);
        a.click();
        if (a.parentNode) {
          a.parentNode.removeChild(a);
        }
      })
      .catch(({ message }) => showError(message));
  }

  exportFee(cmd: string) {
    let dataFilter: any = {};
    if (cmd === "selected" || cmd === "selected-all") {
      const selectedRows = (this.$refs.patentgrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      dataFilter.selectedIds = selectedRows;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        dataFilter = JSON.parse(this.queryStr);
      }
    }
    let feeType = "";
    if (cmd === "all-all" || cmd === "selected-all") {
      feeType = "all";
    }
    const params: any = {
      dataType: "PatentFee",
      extraParams: feeType,
      dataFilter: JSON.stringify(dataFilter),
    };
    Ajax.queryData(API_EXPORT_TASK_SYNC, params)
      .then((task: any) => {
        const url = task.resultUrl || "";
        if (isEmpty(url)) {
          showWarning(MSG_EXPORT_FILE_NOTFOUND);
          return;
        }
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        document.body.appendChild(a);
        a.click();
        if (a.parentNode) {
          a.parentNode.removeChild(a);
        }
      })
      .catch(({ message }) => showError(message));
  }

  onSelectionChanged(selectedRows: any[]) {
    this.hasSelectedItems = selectedRows.length > 0;
  }

  onRowClick(command: string, data: any, event: MouseEvent) {
    if (command === "appNo" || command === "patentNo" || command === "title") {
      if (event.stopPropagation) {
        event.stopPropagation();
      } else {
        event.cancelBubble = true;
      }
      this.showRight = true;
      this.patentDetail = JSON.parse(JSON.stringify(data));

      this.showPrevDetailItem = true;
      this.showNextDetailItem = true;
      const grid = this.$refs.patentgrid as any;
      const dataIndex = grid.tableData.findIndex((item: any) => item.id === data.id);
      if (grid.isFirstPage() && dataIndex === 0) {
        this.showPrevDetailItem = false;
      }
      if (grid.isLastPage() && dataIndex === grid.tableData.length - 1) {
        this.showNextDetailItem = false;
      }
    } else if (command === "doublePatentNo" && !isEmptyOrWhiteSpace(data.doublePatentNo)) {
      Ajax.queryData(
        API_PATENT_LIST,
        { patentNo: `+${data.doublePatentNo}` },
        "POST"
      ).then((patentData) => {
        if (patentData && patentData.data && patentData.data.length > 0) {
          this.showRight = true;
          this.patentDetail = JSON.parse(JSON.stringify(patentData.data[0]));
          this.showPrevDetailItem = false;
          this.showNextDetailItem = false;
        }
      });
    } else if (command === "remark") {
      this.remarkData = {
        id: data.id,
        appNo: data.appNo,
        title: data.title,
        remark: data.remark
      };
      this.showRemarkDialog = true;
    }
  }

  hideFloater() {
    this.showRight = false;
    this.patentDetail = {};
  }

  onColumnConfiged({ data = [], hidden = [] }: {
    data: Array<TableColumnOption>;
    hidden: Array<TableColumnOption>;
  }) {
    const newData: Array<TableColumnOption> = JSON.parse(JSON.stringify(data));
    const newHidden: Array<TableColumnOption> = JSON.parse(
      JSON.stringify(hidden)
    );
    // 删除属性
    newData.forEach((item) => delete item.hidden);
    if (hidden && hidden.length) {
      newData.push(
        ...newHidden.map((val) => Object.assign(val, { hidden: true }))
      );
    }
    this.$store
      .dispatch("patent/saveListLayout", {
        listName: "PATENT",
        layout: JSON.stringify(newData),
      })
      .then(() => this.$store.dispatch("patent/setColumns", []))
      .then(() => this.createTableColumns())
      .catch(({ message }) => showError(message));
  }

  onColumnResize(newWidth: number, column: TableColumn) {
    this.$store.dispatch("patent/setColumnWidth", {
      listName: "PATENT",
      column: column.property,
      newWidth,
    });
  }

  openColumnConfig() {
    const grid = this.$refs.patentgrid as any;
    grid.openColumnConfig();
  }

  onTableDataLoaded() {
    if (this.showRight && !isEmptyOrWhiteSpace(this.dataPosition)) {
      this.patentDetail = null;
      if (this.dataPosition === "Prev") {
        this.onNextDataItem();
      } else if (this.dataPosition === "Next") {
        this.onPrevDataItem();
      }
    }
  }

  // --- ADD PATENT METHOD BEGIN --- //
  get validateRules() {
    return {
      patentNo: {
        required: true,
        message: "请输入申请号/公开号",
        trigger: "blur",
      },
      patentUser: {
        required: true,
        message: "请输入申请人/专利权人",
        trigger: "blur",
      },
      agencyName: {
        required: true,
        message: "请输入代理机构",
        trigger: "blur",
      },
    };
  }

  get addPatentDataApi() {
    return API_PATENT_LIST_BY_OPEN_API;
  }

  showPatentNoDialog(mode: string) {
    this.addPatentInputMode = mode;
    this.addPatentShowInputDialog = true;
  }

  onCloseAddPatentDialog() {
    this.$store.unregisterModule("addpatent");
  }

  closeAddPatentDialog() {
    this.addPatentShowGrid = false;
  }

  onOpenAddPatentDialog() {
    this.$store.registerModule("addpatent", commonStore);
    const columns: Array<TableColumnOption> = [
      {
        prop: "appNo",
        display: "申请号",
        width: 210,
        formatter: (data: any, column: any, value: any) => {
          return this.colFormatters.formatPatentNo(data, column, value, false);
        },
        sortable: false,
      },
      {
        prop: "title",
        display: "名称",
        width: 200,
        sortable: false,
      },
      {
        prop: "appDate",
        display: "申请日",
        width: 110,
        sortable: false,
      },
      {
        prop: "pubNo",
        display: "公开号",
        width: 130,
        sortable: false,
      },
      {
        prop: "pubDate",
        display: "公开日",
        width: 130,
        sortable: false,
      },
      {
        prop: "legalStatus",
        display: "法律状态",
        width: 120,
        sortable: false,
        formatter: (data: any, column: any, value: any) => {
          return this.colFormatters.formatPatentStatus(data);
        },
      },
      {
        prop: "assignees",
        display: "专利权人",
        width: 200,
        sortable: false,
      },
      {
        prop: "applicants",
        display: "申请人",
        width: 200,
        sortable: false,
      },
      {
        prop: "inventors",
        display: "发明人",
        width: 200,
        sortable: false,
      },
      {
        prop: "agencyName",
        display: "代理机构",
        sortable: false,
      }
    ];
    this.$store.dispatch("addpatent/setColumns", columns);
  }

  @Watch("addPatentShowInputDialog")
  clearAddPatentData() {
    if (!this.addPatentShowInputDialog) {
      // this.addPatentSearchData.patentNo = '';
      // this.addPatentSearchData.patentUser = '';
      // this.addPatentSearchData.agencyName = '';
    }
  }

  submitPatentNo() {
    const form = this.$refs.addDataForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const params = { patentNo: this.addPatentSearchData.patentNo };
      this.addPatentQueryStr = JSON.stringify(params);
      this.addPatentShowGrid = true;
      // this.addPatentShowInputDialog = false;
    });
  }

  submitPatentUser() {
    const form = this.$refs.addDataForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const params: { [key: string]: any } = {};
      if (!isEmpty(this.addPatentSearchData.patentUser)) {
        params.patentUser = this.addPatentSearchData.patentUser;
      }
      if (!isEmpty(this.addPatentSearchData.inventor)) {
        params.inventor = this.addPatentSearchData.inventor;
      }
      this.addPatentQueryStr = JSON.stringify(params);
      this.addPatentShowGrid = true;
      // this.addPatentShowInputDialog = false;
    });
  }

  submitAgency() {
    const form = this.$refs.addDataForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const params: { [key: string]: any } = {
        agencyName: this.addPatentSearchData.agencyName,
      };
      this.addPatentQueryStr = JSON.stringify(params);
      this.addPatentShowGrid = true;
      // this.addPatentShowInputDialog = false;
    });
  }

  addSelectedPatent() {
    const selectedRows = (this.$refs.addpatentgrid as any).getSelectedRows();
    if (!selectedRows || !selectedRows.length) {
      showWarning(MSG_001);
      return;
    }
    if (process.env.VUE_APP_TRIAL === "1") {
      showWarning("产品演示系统，未开放本功能");
      return;
    }
    const params: { [key: string]: any } = {
      selectedNos: selectedRows,
    };
    this.$store
      .dispatch("addpatent/post", {
        api: API_PATENT_ADD_BY_OPEN_API,
        params,
      })
      .then((taskId: number) => {
        showSuccess(
          "提交成功，添加数据可能需要几分钟时间，添加完成后，您可以刷新页面查看"
        );
        this.$store.dispatch("patentsync/setTaskId", taskId);
        this.addPatentShowGrid = false;
        this.addPatentShowInputDialog = false;
      });
  }

  addAllPatent() {
    if (process.env.VUE_APP_TRIAL === "1") {
      showWarning("产品演示系统，未开放本功能");
      return;
    }
    const params: { [key: string]: any } = {};
    if (!isEmpty(this.addPatentQueryStr)) {
      Object.assign(params, JSON.parse(this.addPatentQueryStr));
    }
    this.$store
      .dispatch("addpatent/post", {
        api: API_PATENT_ADD_BY_OPEN_API,
        params,
      })
      .then((taskId: string) => {
        showSuccess("添加中...，请在顶部查看添加进度");
        this.$store.dispatch("patentsync/setTaskId", taskId);
        this.addPatentShowGrid = false;
        this.addPatentShowInputDialog = false;
      });
  }

  getAddPatentTitle() {
    let title = "";
    switch (this.addPatentInputMode) {
      case "patentNo":
        title = "按申请号/公开(公告)号添加";
        break;
      case "patentUser":
        title = "按申请人/专利权人添加";
        break;
      case "agencyName":
        title = "按代理机构添加";
        break;
      default:
        title = "";
        break;
    }
    return title;
  }

  // --- ADD PATENT METHOD END --- //
  openSearchPanel() {
    this.showSearch = !this.showSearch;
  }

  onDetailDataChanged(changeType: string) {
    if (changeType === "bibo") {
      this.reloadTreeAndData();
    } else {
      this.searchData();
    }
  }

  openSelectBusinessUserDialog(cmd: string) {
    let params: any = {};
    if (cmd === "selected" || cmd === "selected-unbinding") {
      const selectedRows = (this.$refs.patentgrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    if (cmd === "selected-unbinding" || cmd === "all-unbinding") {
      this.bindingType = "unbinding";
      this.bindingTitle = "解除客户与专利的关联关系";
    } else {
      this.bindingType = "binding";
      this.bindingTitle = "将客户与专利进行关联";
    }
    this.batchPatentFilter = params;
    this.selectUserDialogVisible = true;
  }

  onPatentUserSaveSuccess() {
    this.reloadTreeAndData();
    this.selectUserDialogVisible = false;
  }

  batchDeleteData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected-logic" || cmd === "selected") {
      const selectedRows = (this.$refs.patentgrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `选中的 ${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }

    let api = "";
    if (cmd === "selected-logic" || cmd === "all-logic") {
      api = "logicDeletePatent";
      msg = `后期可从回收站中恢复或彻底删除<br><font color=orange>是否确认将 ${msg} 放入回收站？</font>`;
    } else {
      api = "deletePatent";
      msg = `彻底删除专利，会同时删除如下数据：<br><font color=red>客户的关联关系、相关费用、相关文件等关联数据</font><br><font color=orange>是否确认删除 ${msg}？</font>`;
    }
    showConfirm(msg).then(() => {
      this.$store
        .dispatch(`patent/${api}`, params)
        .then(() => {
          showSuccess("操作完成");
          this.reloadTreeAndData();
        })
        .catch(({ message }) => showError(message));
    });
  }

  batchRecoverData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.patentgrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `选中的 ${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`<font color=orange>是否确认将 ${msg} 从回收站恢复？</font>`).then(() => {
      this.$store
        .dispatch("patent/recoverPatent", params)
        .then(() => {
          showSuccess("操作完成");
          this.reloadTreeAndData();
        })
        .catch(({ message }) => showError(message));
    });
  }

  submitRemark() {
    const params = {
      id: this.remarkData.id,
      remark: this.remarkData.remark
    };
    return this.$store.dispatch("patent/remark", params)
      .then(() => showSuccess("保存成功"))
      .then(() => this.showRemarkDialog = false)
      .then(() => Promise.all([this.searchData()]))
      .catch(({ message }) => showError(message));
  }

  onNextDataItem() {
    if (!this.showRight) {
      return;
    }

    this.dataPosition = "Next";

    const grid = this.$refs.patentgrid as any;
    const data = grid.tableData;

    if (data.length === 0) {
      return;
    }

    let currentItem = this.patentDetail;
    let nextIndex;
    const lastIndex = data.length - 1;

    if (!currentItem) {
      currentItem = data[lastIndex];
      nextIndex = lastIndex;
    } else {
      const index = data.findIndex((item: any) => item.id === currentItem.id);
      nextIndex = index + 1;
      if ((index === -1 || nextIndex > lastIndex) && !grid.isLastPage()) {
        grid.setPageIndex(grid.pageIndex + 1);
        return;
      }
      currentItem = data[nextIndex];
    }

    this.showPrevDetailItem = true;
    this.showNextDetailItem = true;

    if (grid.isFirstPage() && nextIndex <= 0) {
      this.showPrevDetailItem = false;
    }

    if (grid.isLastPage() && nextIndex >= lastIndex) {
      this.showNextDetailItem = false;
    }

    grid.setCurrentDataRow(currentItem);
    this.patentDetail = JSON.parse(JSON.stringify(currentItem));
  }

  onPrevDataItem() {
    if (!this.showRight) {
      return;
    }

    this.dataPosition = "Prev";

    const grid = this.$refs.patentgrid as any;
    const data = grid.tableData;
    let currentItem = this.patentDetail;
    let prevIndex;

    if (!currentItem) {
      currentItem = data[0];
      prevIndex = 0;
    } else {
      const index = data.findIndex((item: any) => item.id === currentItem.id);
      prevIndex = index - 1;
      if ((index === -1 || prevIndex < 0) && !grid.isFirstPage()) {
        grid.setPageIndex(grid.pageIndex - 1);
        return;
      }
      currentItem = data[prevIndex];
    }

    this.showPrevDetailItem = true;
    this.showNextDetailItem = true;

    if (grid.isFirstPage() && prevIndex === 0) {
      this.showPrevDetailItem = false;
    }

    if (grid.isLastPage() && prevIndex === data.length - 1) {
      this.showNextDetailItem = false;
    }

    grid.setCurrentDataRow(currentItem);
    this.patentDetail = JSON.parse(JSON.stringify(currentItem));
  }
}
